
import Typesense from "typesense";
import { SearchIcon, XCircleIcon } from "vue-feather-icons";
import { debounce } from "lodash";

const typesenseClient = new Typesense.Client({
    nodes: [
        {
            host: process.env.TYPESENSE_HOST,
            port: Number(process.env.TYPESENSE_PORT) || 443,
            protocol: process.env.TYPESENSE_PROTOCOL || "https",
        },
    ],
    apiKey: process.env.TYPESENSE_PUBLIC_API_KEY,
    connectionTimeoutSeconds:
        Number(process.env.TYPESENSE_CONNECTION_TIMEOUT) || 2,
});

export default {
    name: "SiteSearch",
    components: {
        SearchIcon,
        XCircleIcon,
    },
    data: () => ({
        thisDomain: process.env.DOMAIN,
        lang: undefined,
        query: null,
        documents: [],
        showSearchResult: false,
        typesenseSearching: false,
        typesenseError: null,
        selectedIndex: -1
    }),
    created() {
        this.debouncedSearch = debounce(this.handleInput, 300); // 300ms debounce delay
    },
    async mounted() {
        const preInputValue = sessionStorage.getItem('siteSearchInput')
        this.cleanUpSearchPersist()
        if (preInputValue) {
            this.query = preInputValue
            this.handleInput()
        }
        this.lang = this.$i18n.locale;
    },
    watch: {
        $route() {
            this.initialState()
        }
    },
    methods: {
        initialState() {
            this.query = "";
            this.documents = [];
            this.showSearchResult = false;
            this.typesenseSearching = false;
            this.typesenseError = null;
            this.selectedIndex = -1;
        },
        cleanUpSearchPersist() {
            sessionStorage.removeItem('siteSearchInput')
            // site search event listeners cleanup
            document.getElementById('SiteSearchInput')?.removeEventListener("keyup", siteSearchInputOnKeyUp, true)
            window.removeEventListener("click", windowClickHandler, true)
        },
        async handleInput() {
            if (!this.query) return
            this.query = this.query.trimStart();

            if (this.query.length > 0) {
                this.typesenseSearching = true;
                this.showSearchResult = true;

                const searchParameters = {
                    q: this.query,
                    query_by: "label",
                    filter_by: `lang:${this.lang || 'en'}`,
                };

                const collectionName =
                    process.env.TYPESENSE_COLLECTION_NAME || "fc_site_search";

                try {
                    const searchResults = await typesenseClient
                        .collections(collectionName)
                        .documents()
                        .search(searchParameters);

                    this.typesenseError = null;
                    this.typesenseSearching = false;

                    const { hits, found } = searchResults;
                    if (found > 0) {
                        this.documents = hits.map((item) => item.document);
                    } else {
                        this.documents = [];
                    }
                } catch (err) {
                    this.typesenseError = err;
                    this.typesenseSearching = false;
                }
            } else {
                this.documents = [];
                this.showSearchResult = false;
            }
        },
        emptyQuery() {
            this.initialState();
        },
        moveUp() {
            if (this.selectedIndex > 0) {
                this.selectedIndex--;
            }
        },
        moveDown() {
            if (this.selectedIndex < this.documents.length - 1) {
                this.selectedIndex++;
            }
        },
        goToItemLink(item) {
            window.location.href = `${this.thisDomain}/${item.url}`;
        },
        goToSelectedItem() {
            if (this.selectedIndex !== -1) {
                this.goToItemLink(this.documents[this.selectedIndex]);
            }
        }
    },
};
