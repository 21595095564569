
import { ChevronRightIcon } from 'vue-feather-icons'
import { detectScrollDirection } from '~/fc/DOMHelper'

export default {
    name: 'BreadCrumb',
    components: { ChevronRightIcon },
    props: ['show'],
    data: () => ({
        visible: true
    }),
    computed: {
        breadcrumbItems() {
            return [{
                url: this.localePath('/'),
                label: this.$t('home')
            }, ...this.$store.state.breadcrumbs]
        }
    },
    mounted() {
        detectScrollDirection(() => {
            this.visible = true
        }, () => {
            this.visible = false
        })
    },
    methods: {
        reload() {
            location.reload()
        }
    }
}
