
import SiteSearch from /* webpackChunkName: "SiteSearch" */ '~/components/ui/SiteSearch.vue'
export default {
  name: 'NavigationWeb',
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ '../../../components/ui/Logo'),
    SiteSearch,
    NavigationLinks: () => import(/* webpackChunkName: "NavigationLinks" */ './NavigationLinks'),
    NavigationAuth: () => import(/* webpackChunkName: "NavigationAuth" */ './NavigationAuth')
  },
  computed: {
    dashboard () { return this.$route.fullPath.includes('/account') },
    isTab () { return this.$store.state.store.isTab }
  }
}
